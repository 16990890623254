const USERS = 'USERS';
const USER_SETTINGS = 'USER_SETTINGS';
const PROJECT_ADD = 'PROJECT_ADD';
const PROJECT_SETTINGS = 'PROJECT_SETTINGS';

export default {
  USERS,
  USER_SETTINGS,
  PROJECT_ADD,
  PROJECT_SETTINGS,
};
